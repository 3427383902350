<template>
  <TransitionRoot
    :show="open"
    as="div"
    class="relative z-40 top-0 bottom-0 overflow-auto flex flex-col h-full bg-neutral-white"
  >
    <TransitionChild
      as="div"
      enter="left-[352px] duration-300 ease-out"
      enter-from="opacity-0 translate-x-[-352px]"
      enter-to="opacity-100"
      leave="left-0 duration-200 ease-in"
      leave-from="opacity-100"
      leave-to="translate-x-[-352px] opacity-0"
    >
      <div class="md:w-[450px] bg-neutral-white p-6 pt-10 z-50">
        <h3 class="text-xl font-geist mb-4">Select Brand</h3>
        <PulseInput ref="searchInput" v-model="query" placeholder="Search..." data-testid="store-select-search-input">
          <template #suffix>
            <button
              v-if="query.length"
              type="button"
              class="p-2 -mr-2 text-primary-700"
              @click.stop.prevent="query = ''"
            >
              <PulseIconClose class="h-3 w-3 fill-path-current" />
            </button>
          </template>
        </PulseInput>
        <TabbedNav :items="navItems" :active="currentTab" @nav:click="handleChange">
          <template #item-active>
            {{ stores.active.length }}
          </template>
          <template #item-inactive>
            {{ stores.inactive.length }}
          </template>
          <template #item-onboarding>
            {{ stores.onboarding.length }}
          </template>
          <template #item-dev>
            {{ stores.dev.length }}
          </template>
          <template #default>
            <div class="mt-4">
              <button
                :class="{
                  'bg-primary-100': allSelectedCategory?.toLowerCase() === currentTab,
                }"
                class="px-3 py-2 mb-2 text-xsm capitalize font-medium text-typography-disabled cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 w-full text-left flex items-center justify-between"
                @click="setAllStores"
              >
                All {{ currentTab }} stores
                <PulseIconCheck v-if="allSelectedCategory?.toLowerCase() === currentTab" class="h-4 w-4" />
              </button>

              <NavDivider />

              <div
                v-for="brand in filteredBrands"
                :key="brand.id"
                class="hover:bg-neutral-100 active:bg-neutral-200 px-3 py-3 text-xsm font-medium cursor-pointer flex items-center justify-between"
                :class="{
                  'bg-primary-100 text-typography-primary': brand.id === store.currentStore?.id,
                  'text-typography-disabled': brand.id !== store.currentStore?.id,
                }"
                :data-testid="`store-select-${brand.slug}`"
                @click="closeStores(brand)"
              >
                {{ brand.name }}
                <PulseIconCheck v-if="brand.id === store.currentStore?.id" class="h-4 w-4" />
              </div>
            </div>
          </template>
        </TabbedNav>
      </div>
    </TransitionChild>
  </TransitionRoot>
  <TransitionRoot :show="open" as="div" class="fixed inset-0">
    <TransitionChild
      as="div"
      class="z-50"
      enter="duration-300 ease-out"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="duration-200 ease-in"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div v-if="open" class="bg-black/25 fixed left-0 right-0 top-0 bottom-0 z-40" @click="$emit('close')" />
    </TransitionChild>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { TransitionChild, TransitionRoot } from '@headlessui/vue';
import { storeToRefs } from 'pinia';

import { fuzzyFactory } from '~/composables/useSearch';
import { useMerchantStore } from '~/store/merchant';
import { brandingSettings } from '~/support/graphql/queries';

const navItems = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Onboarding',
    value: 'onboarding',
  },
  {
    label: 'Dev',
    value: 'dev',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];
const searchableFields = ['name'];

const emit = defineEmits(['close']);

const props = defineProps({
  open: {
    default: true,
    type: Boolean,
  },
});

const searchInput = ref('null');
const query = ref('');
const currentTab = ref('active');
const store = useMerchantStore();
const { stores, allSelectedCategory } = storeToRefs(store);

const currentBrands = computed(() => {
  return stores.value ? stores.value[currentTab.value as keyof typeof stores.value] : ([] as any[]);
});

const filteredBrands = computed(() => {
  return query.value
    ? fuzzyFactory(currentBrands.value, searchableFields)
        .search(query.value)
        .map(({ item }) => item)
    : currentBrands.value;
});

function handleChange(value: string) {
  currentTab.value = value;
}

function setAllStores() {
  store.setCurrentStore(
    stores.value[currentTab.value as keyof typeof stores.value].map(store => ({
      id: store.id,
      logo: store.storeSettings?.storeLogoUrl,
      name: store.name,
      platformId: store.platformId,
      slug: store.slug,
    })),
  );
  emit('close');
}

function closeStores(brand) {
  store.setCurrentStore(brand);
  emit('close');
}

watch(
  () => props.open,
  async open => {
    if (open) {
      query.value = '';

      await nextTick();
      searchInput.value.$el.querySelector('input')?.focus();
    }
  },
);

onMounted(() => {
  window.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      emit('close');
    }
  });
});
</script>
